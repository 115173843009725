import React from 'react'
import PropTypes from 'prop-types'
import { Chart } from 'react-google-charts'
import * as Styled from './chartsStyled'

const PieChart = ({ chartData, withCustomLegend, title, isAudienceTypeChart }) => {
  const createCustomLegend = () => {
    return chartData.values.reduce((arr, item, idx) => {
      if (idx) {
        arr.push(
          <Styled.CustomLegendItem key={item[0] + item[1]}>
            <Styled.CustomLegendColormark color={chartData.colors[idx - 1]} />
            <Styled.CustomLegendText>{item[0]}</Styled.CustomLegendText>
            <Styled.CustomLegendPercentage>{item[1]}%</Styled.CustomLegendPercentage>
          </Styled.CustomLegendItem>,
        )
      }
      return arr
    }, [])
  }

  return (
    <Styled.PieChartWithLegendContainer withCustomLegend={withCustomLegend}>
      {title && <Styled.ChartName>{title}</Styled.ChartName>}
      {withCustomLegend && (
        <Styled.CustomLegend>{createCustomLegend()}</Styled.CustomLegend>
      )}
      <Styled.ChartContainer isAudienceTypeChart={isAudienceTypeChart}>
        <Chart
          className='pie-chart'
          chartType='PieChart'
          loader={<div>Loading</div>}
          data={chartData.values}
          options={{
            backgroundColor: 'transparent',
            enableInteractivity: false, // disabled all interactions
            pieHole: 0.5,
            colors: chartData.colors,
            legend: {
              height: 200,
              position: 'none',
              alignment: 'center',
              textStyle: {
                color: '#272727',
                fontSize: 12,
                fontName: 'Inter',
              },
            },
            width: 350,
            height: 350,
            pieSliceText: 'pecentage',
            pieSliceTextStyle: {
              color: '#ffffff',
              fontSize: 13,
              fontName: 'Inter',
            },
            tooltip: {
              trigger: 'selection',
              showColorCode: true,
              textStyle: {
                color: '#272727',
                fontSize: 12,
                fontName: 'Inter',
              },
            },
            sliceVisibilityThreshold: 0.005,
          }}
        />
      </Styled.ChartContainer>
    </Styled.PieChartWithLegendContainer>
  )
}

PieChart.propTypes = {
  chartData: PropTypes.object.isRequired,
  title: PropTypes.string,
  withCustomLegend: PropTypes.bool,
  isAudienceTypeChart: PropTypes.bool,
}

PieChart.defaultProps = {
  withCustomLegend: false,
  title: '',
  isAudienceTypeChart: false,
}

export default PieChart
