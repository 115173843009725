import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { generalTheme } from '@themes/styledTheme'
import PieChart from '@components/common/charts/PieChart'
import * as Styled from './chartsStyled'

const getMaleAndFemalePerc = genderMtoF => {
  if (Array.isArray(genderMtoF)) {
    return { malePerc: genderMtoF[0], femalePerc: genderMtoF[1] }
  }
  return { malePerc: genderMtoF.male, femalePerc: genderMtoF.female }
}

export const TopPieChart = props => {
  const { genderMtoF, chartTitle, chartAttrText } = props

  const isDataExist = obj =>
    (Array?.isArray(obj) && !!obj?.length) || (obj?.female && obj?.male)

  const createAudienceGenderChartData = useMemo(() => {
    if (!isDataExist(genderMtoF)) {
      return null
    }

    const { malePerc, femalePerc } = getMaleAndFemalePerc(genderMtoF)

    const colorFemale = generalTheme?.color?.infludataSalmon
    const colorMale = generalTheme?.getColorPaletteForPlatform('instagram')?.secondary

    const gendersMap = {
      female: { text: chartAttrText.female, color: colorFemale },
      male: { text: chartAttrText.male, color: colorMale },
    }

    return {
      values: [
        ['Type', 'Value'],
        [gendersMap?.female?.text?.toUpperCase(), femalePerc],
        [gendersMap?.male?.text?.toUpperCase(), malePerc],
      ],
      colors: [gendersMap.female.color, gendersMap.male.color],
    }
  }, [genderMtoF, chartAttrText])

  return (
    <Styled.ChartWrapper>
      <PieChart
        chartData={createAudienceGenderChartData}
        title={chartTitle}
        userindex='instagram'
        withCustomLegend
        isDisabledChart={false}
        notEnoughData={!isDataExist(genderMtoF)}
        isForPdf={false}
      />
    </Styled.ChartWrapper>
  )
}

TopPieChart.propTypes = {
  genderMtoF: PropTypes.object,
  chartAttrText: PropTypes.object,
  chartTitle: PropTypes.string,
}

TopPieChart.defaultProps = {
  genderMtoF: {},
  chartAttrText: {},
  chartTitle: '',
}
