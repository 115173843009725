import styled from 'styled-components'
import windowSizes from '@constants/windowSizes'

/* Horizontal Chart */
export const HorizontalBarChartInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 25px;
  @media screen and (max-width: ${windowSizes.mobile}px) {
    margin-bottom: 15px;
  }
`

export const ChartName = styled.h3`
  font-family: ${props => props.theme.fontFamilyInter};
  font-size: 15px;
  line-height: 19px;
  text-transform: uppercase;
  font-weight: bold;
  color: ${props => props.theme.color.infludataDarkGray};
`
export const Wrapper = styled.div`
  position: relative;

  ${props =>
    props.isDisabled &&
    `
    ${props.theme.disabled}
    opacity: 0.9;
  `}

  .disabled-chart-message__container {
    top: 50%;
    left: 20%;
    transform: none;
  }
`
export const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
`

export const LabelWrapper = styled.div`
  font-family: ${props => props.theme.fontFamilyInter};
  font-size: 15px;
  line-height: 19px;
  min-width: 60%;
  max-width: 60%;
  letter-spacing: 0px;
  color: ${props => props.theme.color.infludataDarkGray};
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: ${windowSizes.mobile}px) {
    font-size: 12px;
    line-height: 15px;
    max-width: 70%;
    min-width: 70%;
  }
`
export const LabelTextWrapper = styled.div`
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (max-width: ${windowSizes.mobile}px) {
    white-space: pre-wrap;
  }
`

export const ItemWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  flex-wrap: nowrap;
`

export const BarWrapper = styled.div`
  flex: 1;
  height: 100%;
  padding: 5px 0;
  display: flex;
  align-items: center;
  border-left: 1px solid ${props => props.theme.color.infludataMediumGray};
`

export const Bar = styled.div`
  width: ${props => props.percent || 50}%;
  height: 20px;
  background: ${props => props.theme.mainDeg(270)};
  border-radius: 0 4px 4px 0;
  position: relative;

  &::before {
    content: '${props => props.barTip}';
    position: absolute;
    top: -25px;
    left: 0;
    display: block;
    font-size: 11px;
    font-family: ${props => props.theme.fontFamilyInter};
    letter-spacing: 0px;
    color: ${props => props.theme.textColor};
    text-transform: uppercase;
  }
`
export const BarTip = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  color: ${props => props.theme.color.infludataDarkGray};
  margin: 0 10px;
`

export const BarTipPercent = styled.span`
  color: ${props => props.theme.getColorPaletteForPlatform('instagram')?.secondary};
`
/* Pie Chart */

export const PieChartWithLegendContainer = styled.div`
  position: relative;
  min-height: 230px;
`

export const ChartContainer = styled.div`
  position: absolute;
  top: 0;
  left: ${props =>
    props.isAudienceTypeChart ? 'calc(50% - 20px)' : 'calc(50% - 100px)'};
  max-height: 200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${windowSizes.mobile}px) {
    position: relative;
    margin-top: 20px;
  }
`

export const CustomLegend = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 25px;
`

export const CustomLegendItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & + & {
    margin-top: 10px;
  }
`

export const CustomLegendColormark = styled.div`
  width: 18px;
  height: 18px;
  background-color: ${props => props.color};
  border-radius: 5px;
`

export const CustomLegendText = styled.div`
  font-size: 13px;
  line-height: 16px;
  margin-left: 5px;
  color: ${props => props.theme.color.infludataDarkGray};
  @media screen and (max-width: ${windowSizes.mobile}px) {
    font-size: 12px;
  }
`

export const CustomLegendPercentage = styled.div`
  font-size: 13px;
  line-height: 16px;
  font-weight: bold;
  margin-left: 5px;
  color: ${props => props.theme.color.infludataDarkGray};
`
