import styled from 'styled-components'
import windowSizes from '@constants/windowSizes'
import { Link } from 'gatsby'

export const MainTopCategoryContainer = styled.div`
  width: 100%;
  padding-left: 75px;
  padding-right: 75px;
  background: ${props => props.theme.infludataMainGradient};
  @media screen and (max-width: ${windowSizes.tablet}px) {
    padding-left: 25px;
    padding-right: 25px;
  }
`
export const TitlesWrapper = styled.div`
  padding-top: 60px;
  max-width: 770px;
  text-align: center;
  margin: 0 auto;
`
export const BottomWrapper = styled.div`
  padding-top: 50px;
  max-width: 770px;
  text-align: center;
  margin: 0 auto;
`

export const TopSmallTitle = styled(Link)`
  max-width: 248px;
  margin: 0 auto;
  font-size: 15px;
  line-height: 19px;
  font-weight: normal;
  text-align: center;
  color: white;
  border-bottom: 1px solid white;
  cursor: pointer;
`

export const Title = styled.h1`
  display: flex !important;
  justify-content: center;
  font-size: 40px;
  line-height: 44px;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
  ${props => props.theme.getTextGradientStyles(props.theme.infludataWhiteGradient)}

  @media screen and (max-width: ${windowSizes.mid}px) {
    display: inherit;
    font-size: 28px;
    line-height: 34px;
  }
  @media screen and (max-width: ${windowSizes.mobile}px) {
    display: flex;
    flex-direction: column;
  }
`
export const BottomTitle = styled.h3`
  display: flex !important;
  justify-content: center;
  font-size: 40px;
  line-height: 44px;
  font-weight: bold;
  text-align: center;
  ${props => props.theme.getTextGradientStyles(props.theme.infludataWhiteGradient)}

  @media screen and (max-width: ${windowSizes.tablet}px) {
    font-size: 28px;
    line-height: 34px;
  }
`

export const CategoryTitle = styled.span`
  font-size: 40px;
  -webkit-text-fill-color: #784e4e;
  padding-left: 10px;
  padding-right: 10px;

  @media screen and (max-width: ${windowSizes.mid}px) {
    font-size: 30px;
  }
`

export const Subtitle = styled.h1`
  font-size: 24px;
  line-height: 26px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 29px;
  ${props => props.theme.getTextGradientStyles(props.theme.infludataWhiteGradient)}

  @media screen and (max-width: ${windowSizes.tablet}px) {
    font-size: 28px;
    line-height: 34px;
  }
`

export const CategoryOverview = styled.div`
  max-width: 1273px;
  min-height: 440px;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.color.white};
  border-radius: 45px;
  padding: 30px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: rgb(0 0 0 / 5%) 0px 20px 25px 0px;

  @media screen and (max-width: ${windowSizes.tablet}px) {
    padding: 20px;
    min-height: 100%;
  }
`
export const BottomBtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
`
export const StyledButton = styled.button`
  padding: 8px 20px;
  border-radius: 9px;
  background-color: ${props => props.theme.color.white};
  margin-bottom: 16px;

  &:focus {
    outline: none;
  }
`

export const UnderBtnText = styled.div`
  font-size: 15px;
  line-height: 19px;
  font-wight: bold;
  text-align: center;
  ${props => props.theme.getTextGradientStyles(props.theme.infludataWhiteGradient)}
`
