import React from 'react'
import PropTypes from 'prop-types'
import { useTable } from 'react-table'

export const TopDetailCategoryTable = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  })

  const toOpenLinkInNewTab = url => {
    window.open(`${url}`, '_blank')
  }

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          // eslint-disable-next-line no-underscore-dangle
          const userID = row?.original?._id
          prepareRow(row)
          return (
            <tr
              {...row.getRowProps()}
              onClick={() =>
                toOpenLinkInNewTab(`https://app.infludata.com/profile/${userID}`)
              }
            >
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

TopDetailCategoryTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
}

TopDetailCategoryTable.defaultProps = {
  columns: [],
  data: [],
}
