import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import useTranslation from '@hooks/useTranslation'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import { TopDetailCategoryTable } from './table'
import { getLinkToUserImage, prettifyBigNum } from '../../../../utils'
import * as Styled from './TopGrowingInfluencersStyled'

import 'react-circular-progressbar/dist/styles.css'

export const TopGrowingInfluencers = props => {
  const { listData } = props
  const {
    currentLang,
    labelStrings,
    languageOptions,
    countries: countryOptions,
  } = useTranslation()
  const { topInfluencers } = labelStrings

  const getPercentage = value => {
    return value ? `${(value * 100)?.toFixed(1)}%` : 0
  }

  const localizeCountry = countryEnglish => {
    const country = countryOptions.find(option => option.value === countryEnglish)
    return country?.label ? country.label : countryEnglish
  }

  const localizeLanguage = lang => {
    const langOption = languageOptions?.find(option => option.value === lang)
    return langOption ? langOption.label : lang
  }

  const styledTable = useMemo(
    () =>
      listData?.map(influence => {
        return {
          ...influence,
          profileImg: (
            <a
              // eslint-disable-next-line no-underscore-dangle
              href={`https://app.infludata.com/profile/${influence?._id}`}
            >
              <Styled.ProfilePhotoWrapper>
                <CircularProgressbarWithChildren
                  minValue={1}
                  maxValue={10}
                  strokeWidth={10}
                  value={(influence?.profileScore).toFixed(1)}
                  styles={{
                    path: {
                      stroke: '#ffb804',
                    },
                    trail: {
                      stroke: '#F4E8E8',
                    },
                    background: {
                      fill: 'red',
                    },
                  }}
                >
                  <Styled.TableCellProfileImg
                    src={getLinkToUserImage(influence?.profilePicURL)}
                    alt=''
                  />
                </CircularProgressbarWithChildren>
                <Styled.TableQualityRating>
                  {influence?.profileScore}/10
                </Styled.TableQualityRating>
              </Styled.ProfilePhotoWrapper>
            </a>
          ),
          growth: (
            <Styled.TableCellContentWrapper>
              <Styled.TableGrowth>
                <Styled.TableActiveCell>
                  +{influence?.monthlyGrowthObject?.monthlyChange}%
                </Styled.TableActiveCell>
                <div>
                  (+{prettifyBigNum(influence?.monthlyGrowthObject?.monthlyDifference)}{' '}
                  Follower)
                </div>
              </Styled.TableGrowth>
            </Styled.TableCellContentWrapper>
          ),
          userNameLocationLang: (
            <Styled.TableCellContentWrapper>
              <Styled.TableActiveCell>{influence?.username}</Styled.TableActiveCell>
              <div>
                {localizeCountry(influence?.country)}
                {influence.city && `, ${influence?.city}`}
              </div>
              <div>{localizeLanguage(influence?.language)}</div>
            </Styled.TableCellContentWrapper>
          ),
          nameBiography: (
            <Styled.TableCellContentWrapper>
              <Styled.TableActiveCell>{influence?.displayName}</Styled.TableActiveCell>
              <div>{influence?.description}</div>
            </Styled.TableCellContentWrapper>
          ),
          followersPlaysPostEngagement: (
            <Styled.TableCellContentWrapper>
              <Styled.TableActiveCell>
                {prettifyBigNum(influence?.followers, 1)}
              </Styled.TableActiveCell>
              <div>{getPercentage(influence?.engagementMean)}</div>
            </Styled.TableCellContentWrapper>
          ),
        }
      }),
    [listData, currentLang],
  )

  const columns = useMemo(
    () => [
      {
        Header: ' ',
        columns: [
          {
            Header: ' ',
            accessor: 'profileImg',
          },
          {
            Header: (
              <Styled.TableHeadWrapper>
                {topInfluencers?.categoryDetailTableGrowthFirst}
                <br />
                {topInfluencers?.categoryDetailTableGrowthLast}
              </Styled.TableHeadWrapper>
            ),
            accessor: 'growth',
          },
          {
            Header: (
              <Styled.TableHeadWrapper>
                <div>{topInfluencers?.categoryDetailTableUsername}</div>
                <div>{topInfluencers?.categoryDetailTableLocation}</div>
                <div>{topInfluencers?.categoryDetailTableLanguage}</div>
              </Styled.TableHeadWrapper>
            ),
            accessor: 'userNameLocationLang',
          },
          {
            Header: (
              <Styled.TableHeadWrapper>
                <div>{topInfluencers?.categoryDetailTableName}</div>
                <div>{topInfluencers?.categoryDetailTableBiography}</div>
              </Styled.TableHeadWrapper>
            ),
            accessor: 'nameBiography',
          },
          {
            Header: (
              <Styled.TableEndHeadWrapper>
                <div>{topInfluencers?.categoryDetailTableFollowers}</div>
                <div>{topInfluencers?.categoryDetailTableEngagement}</div>
              </Styled.TableEndHeadWrapper>
            ),
            accessor: 'followersPlaysPostEngagement',
          },
        ],
      },
    ],
    [topInfluencers, currentLang],
  )

  return (
    <Styled.TopTopGrowingInfluencersContainer>
      <Styled.CategoryElWrapper>
        <Styled.Category>
          {topInfluencers?.categoryDetailTableTitleFirst}
          <br /> {topInfluencers?.categoryDetailTableTitleSecond}
        </Styled.Category>
      </Styled.CategoryElWrapper>
      <Styled.TableWrapper>
        <TopDetailCategoryTable columns={columns} data={styledTable} />
      </Styled.TableWrapper>
    </Styled.TopTopGrowingInfluencersContainer>
  )
}

TopGrowingInfluencers.propTypes = {
  listData: PropTypes.array,
}

TopGrowingInfluencers.defaultProps = {
  listData: [],
}
