import styled from 'styled-components'
import windowSizes from '@constants/windowSizes'

export const TopStatisticsChartsContainer = styled.div`
  padding-left: 50px;
  padding-right: 50px;
  @media screen and (max-width: ${windowSizes.tablet}px) {
    padding-left: 25px;
    padding-right: 25px;
  }
`

export const CategoryElWrapper = styled.div`
  width: 100%;
  margin-bottom: 35px;
`

export const Category = styled.h2`
  width: 100%;
  display: flex !important;
  flex-basis: 100%;
  align-items: center;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  ${props => props.theme.getTextGradientStyles(props.theme.infludataBrownGradient)}
  margin-bottom: 25px;
  &:before,
  &:after {
    content: '';
    flex-grow: 1;
    ${props => props.theme.getTextGradientStyles(props.theme.infludataLightGradientHr)}
    -webkit-text-fill-color: unset;
    -webkit-background-clip: unset;
    height: 1px;
    font-size: 0px;
    line-height: 0px;
    margin: 0px 30px;
    @media screen and (max-width: ${windowSizes.tablet}px) {
      margin: 0px 20px;
    }
  }
  &:after {
    transform: scaleX(-1);
  }
`
export const ChartContainerThird = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: ${windowSizes.tablet}px) {
    flex-direction: column;
  }
`
export const ChartContainerTwice = styled.div`
  width: 100%;
  display: flex;
  @media screen and (max-width: ${windowSizes.tablet}px) {
    flex-direction: column;
  }
`

export const BorderContainer = styled.div`
  width: 100%;
  &:after {
    @media screen and (max-width: ${windowSizes.tablet}px) {
      display: none;
    }
    content: '';
    width: 100%;
    ${props => props.theme.getTextGradientStyles(props.theme.infludataLightGradientHr)}
    -webkit-text-fill-color: unset;
    -webkit-background-clip: unset;
    height: 1px;
  }
`
