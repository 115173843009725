/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React, { useMemo } from 'react'
import LandingLayout from '@layouts/LandingLayout'
import useTranslation from '@hooks/useTranslation'
import availableLangs from '@localization/availableLangs'
import SeoContainer from '@containers/SeoContainer'
import { MaxWidthWrapper } from '@common/styledWrappers'
import TryForFreeButton from '@common/buttons/TryForFreeButton'
import PropTypes from 'prop-types'
import { TopStatistics } from './TopStatistics'
import { TopStatisticsCharts } from './charts'
import { TopGrowingInfluencers } from './TopGrowingInfluencers'
import * as Styled from './styled'

const InstagramTop = props => {
  const { location, pageContext } = props
  const { labelStrings } = useTranslation()
  const { topInfluencers } = labelStrings
  const { pathname } = location
  const currentCategory = pageContext?.el

  const topListCategoryData = useMemo(
    () =>
      currentCategory &&
      require(`../../../../../topListPages/${currentCategory?.listId}.json`),
    [currentCategory],
  )

  const topStatisticsData = useMemo(() => {
    return {
      total_influencers: topListCategoryData?.totalInfluencers,
      average_engagement: topListCategoryData?.averageEngagement,
      expected_reach: topListCategoryData?.expectedReach,
      followers_total: topListCategoryData?.followersTotal,
    }
  }, [topListCategoryData])

  return (
    <>
      <SeoContainer
        pageLang={availableLangs.en}
        pagePath={pathname.replace(/^\/|\/$/g, '')}
        title={`Top 20 ${
          currentCategory?.category ? ` ${currentCategory?.category} ` : ''
        } influencers from ${
          currentCategory?.city
            ? `${currentCategory?.city}, ${currentCategory.country}`
            : currentCategory.country
        } - influData`}
        description='View Country Influencers on influData, is the #1 Influencers Search Engine that allows you to filter by 80,000 
          topics anywhere in the world.'
      />
      <LandingLayout>
        <Styled.MainTopCategoryContainer>
          <MaxWidthWrapper>
            <Styled.TitlesWrapper>
              <Styled.TopSmallTitle to='https://app.infludata.com/'>
                {topInfluencers?.categoryDetailTopLink} {'>>'}
              </Styled.TopSmallTitle>
              <Styled.Title>
                <span>
                  {topInfluencers?.categoryDetailTopTitleFirst}
                  {currentCategory?.category ? (
                    <Styled.CategoryTitle>
                      {currentCategory?.category}
                    </Styled.CategoryTitle>
                  ) : (
                    `${' '}`
                  )}
                </span>
                {topInfluencers?.categoryDetailTopTitleSecond}
              </Styled.Title>
              <Styled.Subtitle>
                {topInfluencers?.categoryDetailTopTitleThird} {currentCategory?.city}
                {currentCategory?.country && currentCategory?.city && `, `}
                {currentCategory?.country}
              </Styled.Subtitle>
            </Styled.TitlesWrapper>
            <Styled.CategoryOverview>
              <TopStatistics topStatisticsData={topStatisticsData} />
              {topListCategoryData?.audienceAnalysis && (
                <TopStatisticsCharts chartData={topListCategoryData?.audienceAnalysis} />
              )}
              <TopGrowingInfluencers listData={topListCategoryData?.users} />
            </Styled.CategoryOverview>
            <Styled.BottomWrapper>
              <Styled.BottomTitle>
                {topInfluencers?.categoryDetailBottomTitleFirst}
              </Styled.BottomTitle>
              <Styled.BottomTitle>
                {topInfluencers?.categoryDetailBottomTitleSecond}
              </Styled.BottomTitle>
              <Styled.BottomTitle>
                {topInfluencers?.categoryDetailBottomTitleLasts}
              </Styled.BottomTitle>
              <Styled.BottomBtnWrapper>
                <TryForFreeButton label={topInfluencers?.categoryDetailBottomButton} />
                <Styled.UnderBtnText>
                  {topInfluencers?.categoryDetailBottomAfterButtonText}
                </Styled.UnderBtnText>
              </Styled.BottomBtnWrapper>
            </Styled.BottomWrapper>
          </MaxWidthWrapper>
        </Styled.MainTopCategoryContainer>
      </LandingLayout>
    </>
  )
}

InstagramTop.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

InstagramTop.defaultProps = {
  location: {},
  pageContext: {},
}

export default InstagramTop
