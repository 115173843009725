import styled from 'styled-components'
import windowSizes from '@constants/windowSizes'

export const ChartWrapper = styled.div`
  max-width: 500px;
  width: 371px;
  min-width: auto;
  min-height: auto;
  padding: 20px;
  border-right: 1px solid;
  border-image: linear-gradient(250deg, #faf7f7 0%, #ceb8b8 100%);
  border-image-slice: 1;
  &:last-child {
    border: none;
  }
  @media screen and (max-width: ${windowSizes.mobile}px) {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 300px;
    width: 100%;
    border: none;
  }
`
