import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from '@hooks/useTranslation'
import * as Styled from './TopStatisticsStyled'
import { prettifyBigNum } from '../../../../utils'

export const TopStatistics = props => {
  const { topStatisticsData } = props
  const { labelStrings } = useTranslation()
  const { topInfluencers } = labelStrings

  const getPercentage = value => {
    return value ? `${(value * 100)?.toFixed(1)}%` : 0
  }

  return (
    <Styled.TopStatisticsContainer>
      <Styled.TopStatisticsWrapper>
        <Styled.TopStatisticTitle>
          {topInfluencers?.categoryDetailTotalInfluencers}
        </Styled.TopStatisticTitle>
        <Styled.TopStaticsData>
          {prettifyBigNum(topStatisticsData?.total_influencers)?.toUpperCase()}
        </Styled.TopStaticsData>
      </Styled.TopStatisticsWrapper>
      <Styled.TopStatisticsWrapper>
        <Styled.TopStatisticTitle>
          {topInfluencers?.categoryDetailAverageEngagement}
        </Styled.TopStatisticTitle>
        <Styled.TopStaticsData>
          {getPercentage(topStatisticsData?.average_engagement)}
        </Styled.TopStaticsData>
      </Styled.TopStatisticsWrapper>
      <Styled.TopStatisticsWrapper>
        <Styled.TopStatisticTitle>
          {topInfluencers?.categoryDetailExpectedReach}
        </Styled.TopStatisticTitle>
        <Styled.TopStaticsData>
          {prettifyBigNum(topStatisticsData?.expected_reach)?.toUpperCase()}
        </Styled.TopStaticsData>
      </Styled.TopStatisticsWrapper>
      <Styled.TopStatisticsWrapper>
        <Styled.TopStatisticTitle>
          {topInfluencers?.categoryDetailFollowersInTotal}
        </Styled.TopStatisticTitle>
        <Styled.TopStaticsData>
          {prettifyBigNum(topStatisticsData?.followers_total)?.toUpperCase()}
        </Styled.TopStaticsData>
      </Styled.TopStatisticsWrapper>
    </Styled.TopStatisticsContainer>
  )
}

TopStatistics.propTypes = {
  topStatisticsData: PropTypes.object,
}

TopStatistics.defaultProps = {
  topStatisticsData: {},
}
