import React from 'react'
import PropTypes from 'prop-types'
import HorizontalBarChart from '../../../../common/charts/HorizontalChart'
import * as Styled from './chartsStyled'

export const TopHorizontalChart = props => {
  const { chartData, chartTitle } = props
  return (
    <Styled.ChartWrapper>
      <HorizontalBarChart
        userindex='instagram'
        chartData={chartData}
        chartTitle={chartTitle}
      />
    </Styled.ChartWrapper>
  )
}

TopHorizontalChart.propTypes = {
  chartData: PropTypes.array,
  chartTitle: PropTypes.string,
}

TopHorizontalChart.defaultProps = {
  chartData: [],
  chartTitle: '',
}
