import styled from 'styled-components'
import windowSizes from '@constants/windowSizes'

export const TopStatisticsContainer = styled.div`
  padding-left: 105px;
  padding-right: 105px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  @media screen and (max-width: ${windowSizes.tablet}px) {
    padding-left: 25px;
    padding-right: 25px;
    flex-direction: column;
  }
`
export const TopStatisticsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: ${windowSizes.tablet}px) {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`
export const TopStatisticTitle = styled.h3`
  text-transform: uppercase;
  color: ${props => props.theme.color.infludataDarkGray};
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
`

export const TopStaticsData = styled.div`
  ${props => props.theme.getTextGradientStyles(props.theme.infludataMainGradient)};
  font-weight: bold;
  font-size: 32px;
  line-height: 34px;
  align-items: center;
`
