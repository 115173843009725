import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import useTranslation from '@hooks/useTranslation'
import { TopPieChart } from './TopPieChart'
import * as Styled from './styled'
import { TopHorizontalChart } from './TopHorizontalChart'

export const TopStatisticsCharts = props => {
  const { chartData } = props
  const {
    genderMtoF,
    averageBirthyearArray,
    cityArray,
    countryArray,
    languageArray,
  } = chartData
  const { labelStrings } = useTranslation()
  const { topInfluencers } = labelStrings

  const formattedChartData = useCallback(
    (data, labelKey) => {
      return data?.map(item => ({
        value: item.value,
        label: item[labelKey],
        barTip: {
          percent: `${item.value}%`,
        },
      }))
    },
    [countryArray, averageBirthyearArray, cityArray],
  )

  const ageGroupsData = formattedChartData(averageBirthyearArray, 'birthyear')
  const cityData = formattedChartData(cityArray, 'city')
  const countryData = formattedChartData(countryArray, 'country')
  const languageData = formattedChartData(languageArray, 'language')

  const pieChartAttr = {
    female: topInfluencers.pieChartAttrFemale,
    male: topInfluencers.pieChartAttrMale,
  }

  const sortedAgeGroupsData = ageGroupsData
    .map(item => {
      return {
        age: item.label.split('-')[0],
        ...item,
      }
    })
    .sort((a, b) => a.age - b.age)

  return (
    <Styled.TopStatisticsChartsContainer>
      <Styled.CategoryElWrapper>
        <Styled.Category>{topInfluencers?.categoryDetailChartTitle}</Styled.Category>
        <Styled.BorderContainer>
          <Styled.ChartContainerThird>
            {countryArray?.length && (
              <TopHorizontalChart
                chartData={countryData}
                chartTitle={topInfluencers.chartCountry}
              />
            )}
            {cityArray?.length && (
              <TopHorizontalChart
                chartData={cityData}
                chartTitle={topInfluencers.chartCity}
              />
            )}
            {languageArray?.length && (
              <TopHorizontalChart
                chartData={languageData}
                chartTitle={topInfluencers.chartLanguage}
              />
            )}
          </Styled.ChartContainerThird>
        </Styled.BorderContainer>
        <Styled.ChartContainerTwice>
          {averageBirthyearArray?.length && (
            <TopHorizontalChart
              chartData={sortedAgeGroupsData}
              chartTitle={topInfluencers.chartAgeGroups}
            />
          )}
          {genderMtoF && Object?.keys(genderMtoF)?.length && (
            <TopPieChart
              genderMtoF={genderMtoF}
              chartTitle={topInfluencers.chartGender}
              chartAttrText={pieChartAttr}
            />
          )}
        </Styled.ChartContainerTwice>
      </Styled.CategoryElWrapper>
    </Styled.TopStatisticsChartsContainer>
  )
}

TopStatisticsCharts.propTypes = {
  chartData: PropTypes.object,
}

TopStatisticsCharts.defaultProps = {
  chartData: {},
}
