import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Styled from './chartsStyled'

const ONE_LINE_NAME_LENGTH = 25
const GERMAN_SS_SYMBOL = 'ß'

const HorizontalBarChart = ({ chartData, userindex, chartTitle }) => {
  const [renderedChartData, setRenderedChartData] = useState([])
  const [maxValue, setMaxValue] = useState(0)

  const prepareChartDataForRender = data => {
    const getMaxValue = Math.max.apply(
      null,
      data?.map(item => item.value),
    )
    setMaxValue(getMaxValue)
    return chartData.map(item => {
      return {
        value: item.value,
        label: item.label,
        barTip: {
          percent: `${item.value}%`,
        },
      }
    })
  }

  useEffect(() => {
    const preparedChartData = prepareChartDataForRender(chartData)
    setRenderedChartData(preparedChartData)
  }, [chartData])

  const isLongChartName = chartTitle && chartTitle?.length > ONE_LINE_NAME_LENGTH

  const uppercaseLabelBeforeRender = label => {
    if (label?.includes(GERMAN_SS_SYMBOL)) {
      // browser by default replaces ß to ss symbols when uppercased, and we want to keep ß
      const splitted = label.split(GERMAN_SS_SYMBOL)
      return splitted.map(str => str.toUpperCase()).join(GERMAN_SS_SYMBOL)
    }
    return label.toUpperCase()
  }
  return (
    <Styled.Wrapper isLongChartName={isLongChartName}>
      <Styled.HorizontalBarChartInfo>
        <Styled.ChartName>{chartTitle}</Styled.ChartName>
      </Styled.HorizontalBarChartInfo>

      <Styled.ChartWrapper>
        {renderedChartData.map(item => (
          <Styled.ItemWrapper key={item.label}>
            <Styled.LabelWrapper withCount={false} className='horizontal-bar-chat_label'>
              <Styled.LabelTextWrapper>
                {uppercaseLabelBeforeRender(item.label)}
              </Styled.LabelTextWrapper>

              <Styled.BarTip userindex={userindex}>
                <Styled.BarTipPercent platform={userindex}>
                  {item.barTip.percent}
                </Styled.BarTipPercent>
              </Styled.BarTip>
            </Styled.LabelWrapper>

            <Styled.BarWrapper userindex={userindex}>
              <Styled.Bar percent={(item.value / maxValue) * 100} platform={userindex} />
            </Styled.BarWrapper>
          </Styled.ItemWrapper>
        ))}
      </Styled.ChartWrapper>
    </Styled.Wrapper>
  )
}

HorizontalBarChart.propTypes = {
  chartData: PropTypes.array.isRequired,
  userindex: PropTypes.string,
  chartTitle: PropTypes.string,
}
HorizontalBarChart.defaultProps = {
  userindex: '',
  chartTitle: '',
}

export default HorizontalBarChart
