import styled from 'styled-components'
import windowSizes from '@constants/windowSizes'

export const TopTopGrowingInfluencersContainer = styled.div`
  padding-left: 50px;
  padding-right: 50px;
  @media screen and (max-width: ${windowSizes.tablet}px) {
    padding-left: 25px;
    padding-right: 25px;
  }
`

export const CategoryElWrapper = styled.div`
  width: 100%;
`

export const ProfilePhotoWrapper = styled.div`
  max-width: 100px;
  min-height: auto;
  [data-test-id='CircularProgressbarWithChildren__children'] {
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
    &:hover {
      opacity: 0.6;
    }
  }
`

export const Category = styled.h2`
  width: 100%;
  display: flex !important;
  flex-basis: 100%;
  align-items: center;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  ${props => props.theme.getTextGradientStyles(props.theme.infludataBrownGradient)}
  margin-bottom: 45px;
  &:before,
  &:after {
    content: '';
    flex-grow: 1;
    ${props => props.theme.getTextGradientStyles(props.theme.infludataLightGradientHr)}
    -webkit-text-fill-color: unset;
    -webkit-background-clip: unset;
    height: 1px;
    font-size: 0px;
    line-height: 0px;
    margin: 0px 45px;
    @media screen and (max-width: ${windowSizes.tablet}px) {
      margin: 0px 20px;
    }
  }
  &:after {
    transform: scaleX(-1);
  }
`
export const TableWrapper = styled.div`
  overflow-x: auto;
  table {
    width: 100%;
    border-collapse:collapse;  
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      margin: 0;
      text-align: left;
    }
    thead {
      tr {
        position: relative;
        &:before {
          content: '';
          position: absolute;
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        transition: 0.25s ease background;
        &:hover {
          background: rgba(255, 188, 0, .1)
        } 
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          flex-grow: 1;
          background: rgb(255,255,255);
          background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(223,205,205,1) 50%, rgba(255,255,255,1) 100%);
          width: 100%;
          height: 1px;
          font-size: 0px;
          line-height: 0px;
        }
      }
    }
    td {
      margin: 0;
      padding: 1.5rem 0.5rem;
      &:first-child {
        min-width: 110px;
        @media screen and (max-width: ${windowSizes.mobile}px) {
          margin-right: 30px;
        }
      } 
    },
  }
`
export const TableHeadWrapper = styled.div`
  min-width: 90px;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: 15px;
  color: ${props => props.theme.color.infludataGray};
`
export const TableEndHeadWrapper = styled.div`
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  margin-right: 0;
  color: ${props => props.theme.color.infludataGray};
`

export const TableCellProfileImg = styled.img`
  max-width: 80px;
  width: 100%;
  min-height: auto;
  border-radius: 50%;
  @media screen and (max-width: ${windowSizes.mobile}px) {
    max-width: 80px;
  }
`

export const TableQualityRating = styled.div`
  display: flex !important;
  justify-content: center;
  padding-top: 10px;
  ${props => props.theme.getTextGradientStyles(props.theme.infludataMainGradient)};
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  letter-spacing: 0.5px;
`

export const TableGrowth = styled.div`
  display: flex;
  flex-direction: column;
`
export const TableCellContentWrapper = styled.div`
  max-width: 300px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${props => props.theme.color.infludataBlack};
  font-size: 15px;
  line-height: 19px;
  padding-left: 15px;
  padding-right: 15px;
`
export const TableActiveCell = styled.div`
  color: ${props => props.theme.color.infludataSalmon};
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
`
